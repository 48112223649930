<template>
  <div>
    <div class="flex justify-center bg-black">
      <base-input
        v-model="widgetHeading"
        class="bg-black text-white placeholder-white w-full focus:outline-none cursor-text py-1.5 px-4 text-center"
        placeholder="[HEADER]"
      />
    </div>
    <quill-text-editor
      v-model:content="widgetContent"
      :toolbar="`#${toolbarContainerId}`"
      placeholder="Add content here..."
    >
      <template #toolbar>
        <div
          :id="toolbarContainerId"
        >
          <span class="ql-formats">
            <button class="ql-bold" />
            <button class="ql-italic" />
          </span>
          <span class="ql-formats">
            <button class="ql-image" />
          </span>
          <span class="ql-formats">
            <button
              class="ql-list"
              value="ordered"
            />
            <button
              class="ql-list"
              value="bullet"
            />
          </span>
          <span class="ql-formats">
            <button
              class="ql-align"
              value
            />
            <button
              class="ql-align"
              value="center"
            />
            <button
              class="ql-align"
              value="right"
            />
            <button
              class="ql-align"
              value="justify"
            />
          </span>
          <span class="ql-formats">
            <button
              class="text-gray-200 hover:text-white mr-2"
              @click="handleDeleteWidget()"
            >
              DEL
            </button>
          </span>
        </div>
      </template>
    </quill-text-editor>
  </div>
</template>

<script>
import { computed } from 'vue';
import QuillTextEditor from '@/components/QuillTextEditor.vue';
import BaseInput from '@/components/generic-components/BaseInput.vue';

export default {
    name: 'ContentWidget',

    components: {
        QuillTextEditor,
        BaseInput
    },

    props: {
        heading: {
            type: String,
            required: true
        },
        content: {
            type: String,
            required: true
        },
        widgetIndex: {
            type: [String, Number],
            required: true
        }
    },

    emits: [
        'update:heading',
        'update:content',
        'deleteWidget'
    ],

    setup (props, { emit }) {
        const toolbarContainerId = computed(() => 'contentWidgetToolbar' + props.widgetIndex);

        // 2-way data bind logic
        const widgetHeading = computed({
            get () {
                return props.heading;
            },
            set (value) {
                emit('update:heading', value);
            }
        });
        const widgetContent = computed({
            get () {
                return props.content;
            },
            set (value) {
                emit('update:content', value);
            }
        });

        // delete widget logic
        const handleDeleteWidget = () => {
            emit('deleteWidget');
        };

        return {
            toolbarContainerId,
            widgetHeading,
            widgetContent,
            handleDeleteWidget
        };
    }
};
</script>
